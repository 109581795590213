import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  Experimental_CssVarsProvider as CssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from "@mui/material/styles";
import { CssVarsProvider as JoyCssVarsProvider } from "@mui/joy/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {
  createTheme,
  experimental_extendTheme as extendTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import "./css/satoshi.css"; // Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDSi2T-E7tiJpLUFvJb0Sf_IF4y6Jv4x-A",
  authDomain: "futurelink-cab08.firebaseapp.com",
  projectId: "futurelink-cab08",
  storageBucket: "futurelink-cab08.appspot.com",
  messagingSenderId: "906792324193",
  appId: "1:906792324193:web:a85d2174adf03ee5c02e20",
  measurementId: "G-5P6TKZ85T4",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const materialTheme = materialExtendTheme();
// let theme2 = extendTheme();
// theme2 = responsiveFontSizes(theme);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <MaterialCssVarsProvider
      theme={{ [MATERIAL_THEME_ID]: responsiveFontSizes(theme) }}
    >
      <JoyCssVarsProvider>
        <CssBaseline enableColorScheme />
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </JoyCssVarsProvider>
    </MaterialCssVarsProvider>
  </React.StrictMode>
);
